<template>
  <AppProvider>
    <RouterView />
  </AppProvider>
</template>

<script setup>
import AppProvider from '@/components/providers/AppProvider.vue';
import { checkApiStatus } from '@/api/checkApiStatus';
import { useAppConfigStore } from '@/stores/app-config';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const appConfigStore = useAppConfigStore();
const { getApiState } = storeToRefs(appConfigStore);

// watching for changes in the api status
watch(getApiState.value, async newVal => {
  checkApiStatus({
    status: newVal.status
  });
});
</script>
