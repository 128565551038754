import { i18n } from '@/i18';
import { defineStore } from 'pinia';

export const useAppConfigStore = defineStore('app-config', {
  state: () => ({
    theme: localStorage.getItem('app-config') ? JSON.parse(localStorage.getItem('app-config')).theme : null,
    locales: {
      supported: import.meta.env.VITE_SUPPORTED_LOCALES.split(',') || ['en', 'es'],
      current: localStorage.getItem('user-locale') || import.meta.env.VITE_DEFAULT_LOCALE,
      fallback: import.meta.env.VITE_FALLBACK_LOCALE
    },
    api: {
      status: null,
      source: null
    },
    sidebar: {
      collapsed: localStorage.getItem('app-config') ? JSON.parse(localStorage.getItem('app-config')).sidebar?.collapsed : false,
      width: 240,
      collapsedWidth: 64,
      collapsedIconWidth: 22
    },
    isMobile: false,
    mobileBreakpoint: 992
  }),

  getters: {
    getTheme() {
      return this.theme;
    },
    getApiState() {
      return this.api;
    },
    getSidebarOptions() {
      return this.sidebar;
    },
    getIsMobile() {
      return this.isMobile;
    },
    getMobileBreakpoint() {
      return this.mobileBreakpoint;
    },
    getLocales() {
      return this.locales;
    }
  },

  actions: {
    setTheme({ theme }) {
      if (theme) {
        localStorage.setItem('app-config', JSON.stringify({ ...JSON.parse(localStorage.getItem('app-config')), theme }));
        document.documentElement.setAttribute('data-theme-dark', 'true');
      } else {
        document.documentElement.removeAttribute('data-theme-dark');
        // getting app-config from localStorage
        const appConfig = JSON.parse(localStorage.getItem('app-config'));
        if (appConfig && appConfig.hasOwnProperty('theme')) {
          delete appConfig.theme;
          localStorage.setItem('app-config', JSON.stringify(appConfig));
        }
      }

      this.theme = theme;
    },

    setApiState({ status = null, source = null }) {
      // clear previous state
      this.api.status = null;
      this.api.source = null;

      // set new state
      this.api.status = status;
      this.api.source = source;
    },

    setSidebarOptions({ collapsed = null, width = null, collapsedWidth = null, collapsedIconWidth = null }) {
      if (collapsed !== null) {
        this.sidebar.collapsed = collapsed;
      }
      if (width !== null) {
        this.sidebar.width = width;
      }
      if (collapsedWidth !== null) {
        this.sidebar.collapsedWidth = collapsedWidth;
      }
      if (collapsedIconWidth !== null) {
        this.sidebar.collapsedIconWidth = collapsedIconWidth;
      }

      // save sidebar options to localStorage
      localStorage.setItem('app-config', JSON.stringify({ ...JSON.parse(localStorage.getItem('app-config')), sidebar: this.sidebar }));
    },

    setIsMobile({ isMobile }) {
      this.isMobile = isMobile;
    },

    async setNewLocale({ newLocale }) {
      i18n.global.locale.value = newLocale;

      document.querySelector('html').setAttribute('lang', newLocale);
      localStorage.setItem('user-locale', newLocale);

      // if (!i18n.global.availableLocales.includes(newLocale)) {
      //   const { default: locale } = await import(`@/i18/locales/${newLocale}.json`);
      //   i18n.global.setLocaleMessage(newLocale, locale);
      // }

      return newLocale;
    }
  }
});
