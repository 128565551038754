import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES } from './routes';
import useSetupRouterGuards from './guards';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes: ROUTES
});

export function setupRouter(app) {
  app.use(router);

  useSetupRouterGuards();
}

export default router;
