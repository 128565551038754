import logoDefault from '@/assets/img/logo.svg';
import logoStacked from '@/assets/img/logo-stacked.svg';
import logoWhiteDefault from '@/assets/img/logo-white.svg';
import logoWhiteStacked from '@/assets/img/logo-white-stacked.svg';
import {
  GridOutline,
  HomeOutline,
  ImagesOutline,
  ListOutline,
  MailOutline,
  PeopleOutline,
  PersonOutline,
  SettingsOutline,
  ChatbubbleEllipsesOutline,
  CalendarOutline,
  DocumentTextOutline,
  FilterOutline
} from '@vicons/ionicons5';
import AdsIcon from '@/components/icons/AdsIcon.vue';

export const CONSTANTS_COMPANY = {
  name: 'Listings to Leads',
  contact: {
    phone: '888-508-8322',
    email: 'support@listingstoleads.com',
    address: '9580 Oak Ave. Parkway, Ste. 7-127, Folsom, CA 95630'
  },
  social: {
    facebook: 'https://www.facebook.com/listingstoleads',
    twitter: 'https://twitter.com/ListingstoLeads',
    instagram: 'https://www.instagram.com/listingstoleads',
    linkedin: 'https://www.linkedin.com/company/listings-to-leads',
    youtube: 'https://www.youtube.com/user/MrListingstoLeads'
  },
  founders: [
    {
      name: 'Scott Pierce',
      role: 'Founder & CEO',
      photo: 'https://www.listingstoleads.com/asset-new2/img/scott.jpg',
      email: 'scott@listingstoleads.com'
    },
    {
      name: 'Vincent Meza',
      role: 'Vice-President of Sales',
      photo: 'https://www.listingstoleads.com/asset-new2/img/vince.jpg',
      email: 'vince@listingstoleads.com'
    }
  ],
  logos: {
    default: logoDefault,
    stacked: logoStacked,
    whiteDefault: logoWhiteDefault,
    whiteStacked: logoWhiteStacked
  }
};

export const CONSTANTS_URL = {
  baseUrl: import.meta.env.VITE_URL || 'https://api.listingstoleads.xyz/',
  baseApiUrl: import.meta.env.VITE_API_URL || 'https://api.listingstoleads.xyz/api/',
  mediaUrl: import.meta.env.VITE_MEDIA_URL || 'https://api.listingstoleads.xyz/media/',
  mediaPreviewUrl: import.meta.env.VITE_MEDIA_PREVIEW_URL || 'https://api.listingstoleads.xyz/media/preview/',
  templatesUrl: 'https://api.listingstoleads.xyz/templates/',
  landingPageUrl: import.meta.env.VITE_LANDING_PAGE_BASE_URL || 'https://searchallproperties.xyz/',
  landingPageReviewsUrl: subdomain => `${CONSTANTS_URL.landingPageUrl}reviews/${subdomain}`
};

export const CONSTANTS_BRAND_BACKROUND_TYPES = [
  { label: 'Dark', value: 'dark' },
  { label: 'Light', value: 'light' },
  { label: 'Auto', value: 'auto' },
  { label: 'Custom', value: 'custom' }
];

export const CONSTANTS_DEFAULT_BACKROUND_COLORS = {
  dark: {
    backgroundColor: '#1b1d20',
    backgroundColorInverse: '#ffffff'
  },
  light: {
    backgroundColor: '#ffffff',
    backgroundColorInverse: '#111111'
  }
};

export const CONSTANTS_BRAND_THEMES = [
  {
    name: 'Allen Tate',
    value: 'allen-tate',
    colors: {
      primary: '#8b0b04',
      secondary: '#56555c',
      tertiary: '#b3b5b8'
    },
    font: 'Lato',
    backgroundType: 'custom',
    backgroundColor: '#f6f1d4',
    backgroundColorInverse: '#111111'
  },
  {
    name: 'Berkshire Hathaway',
    value: 'berkshire-hathaway',
    colors: {
      primary: '#552448',
      secondary: '#000000',
      tertiary: '#EAE3D4'
    },
    font: 'Georgia',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Century 21',
    value: 'century-21',
    colors: {
      primary: '#BEAF87',
      secondary: '#252526',
      tertiary: '#A19276'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Coldwell Banker',
    value: 'coldwell-banker',
    colors: {
      primary: '#012169',
      secondary: '#012169',
      tertiary: '#63666A'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Crye Leike',
    value: 'crye-leike',
    colors: {
      primary: '#cc0000',
      secondary: '#212529',
      tertiary: '#cc0000'
    },
    font: 'Nunito',
    backgroundType: 'custom',
    backgroundColor: '#ecf0f1',
    backgroundColorInverse: '#111111'
  },
  {
    name: 'Douglas Elliman',
    value: 'douglas-elliman',
    colors: {
      primary: '#009CBD',
      secondary: '#53565A',
      tertiary: '#009CBD'
    },
    font: 'Avenir Next LT Pro',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Engel & Völkers',
    value: 'engel-volkers',
    colors: {
      primary: '#ff0000',
      secondary: '#ff0000',
      tertiary: '#444444'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'ERA',
    value: 'era',
    colors: {
      primary: '#C8102E',
      secondary: '#250E62',
      tertiary: '#920337'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'eXp Realty',
    value: 'exp-realty',
    colors: {
      primary: '#1b1e2a',
      secondary: '#F5821F',
      tertiary: '#58595a'
    },
    font: 'Roboto',
    backgroundType: 'dark',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.dark.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.dark.backgroundColorInverse
  },
  {
    name: 'Home Smart',
    value: 'home-smart',
    colors: {
      primary: '#ed1a3c',
      secondary: '#000000',
      tertiary: '#504E55'
    },
    font: 'proxima-nova',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Howard Hanna',
    value: 'howard-hanna',
    colors: {
      primary: '#0c653a',
      secondary: '#fbce20',
      tertiary: '#0c653a'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Keller Williams',
    value: 'keller-williams',
    colors: {
      primary: '#B40101',
      secondary: '#999999',
      tertiary: '#666666'
    },
    font: 'proxima-nova',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'MVP Realty',
    value: 'mvp-realty',
    colors: {
      primary: '#003350',
      secondary: '#000000',
      tertiary: '#59585e'
    },
    font: 'Helvetica Neue',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Pearson Smith Realty',
    value: 'pearson-smith-realty',
    colors: {
      primary: '#18b4c9',
      secondary: '#23282d',
      tertiary: '#23282d'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Realty One',
    value: 'realty-one',
    colors: {
      primary: '#C5A95E',
      secondary: '#000000',
      tertiary: '#777777'
    },
    font: 'Avenir',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'RE/MAX',
    value: 'remax',
    colors: {
      primary: '#DC1C2E',
      secondary: '#003DA5',
      tertiary: '#B22B2E'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: "Sotheby's International",
    value: 'sothebys-international',
    colors: {
      primary: '#002349',
      secondary: '#666666',
      tertiary: '#999999'
    },
    font: 'Avenir Next',
    backgroundType: 'custom',
    backgroundColor: '#002349',
    backgroundColorInverse: '#ffffff'
  },
  {
    name: 'William Raveis',
    value: 'william-raveis',
    colors: {
      primary: '#5889b5',
      secondary: '#5D89B4',
      tertiary: '#41464b'
    },
    font: 'proxima-nova',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Windermere',
    value: 'windermere',
    colors: {
      primary: '#002A4E',
      secondary: '#5D6468',
      tertiary: '#009cde'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Default',
    value: 'default',
    colors: {
      primary: '#002349',
      secondary: '#B80D0D',
      tertiary: '#DEE2E6'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Custom',
    value: 'custom',
    colors: {
      primary: '#01295c',
      secondary: '#527db2',
      tertiary: '#002a5c'
    }
  }
];

export const CONSTANTS_BRAND_THEMES_FONTS = [
  { label: 'Default', value: 'system-ui' },
  { label: 'Avenir', value: 'Avenir' },
  { label: 'Avenir Next', value: 'Avenir Next' },
  { label: 'Avenir Next LT Pro', value: 'Avenir Next LT Pro' },
  { label: 'Futura Pt', value: 'futura-pt' },
  { label: 'Lato', value: 'Lato' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Proxima Nova', value: 'proxima-nova' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Nunito', value: 'Nunito' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Helvetica Neue', value: 'Helvetica Neue' }
];

// SIDEBAR MAIN ROUTE
export const SIDEBAR_MAIN_ROUTES = [
  { name: 'GetStartedView', path: '/get-started', title: 'Get Started', icon: HomeOutline },
  { name: 'AdsView', path: '/ads', title: 'Ads', icon: AdsIcon },
  { name: 'ListingsView', path: '/listings', title: 'Listings', icon: ListOutline },
  { name: 'PdfGuidesView', path: '/guides', title: 'PDF Guides', icon: DocumentTextOutline },
  { name: 'SocialView', path: '/social', title: 'Social Content', icon: CalendarOutline },
  { name: 'LandingPagesView', path: '/pages', title: 'Landing Pages', icon: GridOutline },
  { name: 'ReviewsView', path: '/reviews', title: 'Client Reviews', icon: ChatbubbleEllipsesOutline },
  { name: 'FollowUpsView', path: '/follow-ups', title: 'Follow Ups', icon: MailOutline },
  { name: 'LeadsView', path: '/leads', title: 'Leads', icon: FilterOutline },
  { name: 'MediaLibraryView', path: '/media-library', title: 'Library', icon: ImagesOutline },
  { name: 'ProfileGeneralView', path: '/profile/general', title: 'Profile', icon: PersonOutline },
  { name: 'AccountPreferencesView', path: '/account/preferences', title: 'Account Settings', icon: SettingsOutline }
];
// SIDEBAR BROKER ROUTE
export const SIDEBAR_BROKER_ROUTES = [
  { name: 'BrokerAgentsView', path: '/broker/agents', title: 'Agents', icon: PeopleOutline },
  { name: 'BrokerOfficeSettingsView', path: '/broker/office-settings', title: 'Office Settings', icon: SettingsOutline }
];

// SUPPORTED TAGS AND NAMES FOR EMAILS TEMPLATES
export const SUPPORTED_EMAIL_TAGS = ['{signature}'];
export const SUPPORTED_AGENT_TAGS = [
  '{first_name}',
  '{last_name}',
  '{full_name}',
  '{agent_first_name}',
  '{agent_last_name}',
  '{agent_full_name}',
  '{agent_username}',
  '{agent_company}',
  '{agent_email}',
  '{agent_phone}'
];
export const SUPPORTED_LISTING_TAGS = [
  '{listing_address}',
  '{listing_street}',
  '{listing_city}',
  '{listing_state}',
  '{listing_zip_code}',
  '{listing_status}',
  '{listing_link}',
  '{listing_price}',
  '{listing_headline}',
  '{listing_description}',
  '{listing_bedrooms}',
  '{listing_bathrooms}',
  '{listing_living_area}',
  '{listing_image}'
];
export const SUPPORTED_LANDING_PAGE_TAGS = [
  '{landing_page_url}',
  '{landing_page_city}',
  '{landing_page_location}',
  '{landing_page_sold_homes}'
];
export const SUPPORTED_GUIDE_TAGS = ['{report_name}', '{report_link}'];

export const SUPPORTED_CURRENCIES = [
  { label: '$ - United States Dollars', value: 'USD' },
  { label: '€ - Euro', value: 'EUR' },
  { label: '£ - United Kingdom Pounds', value: 'GBP' },
  { label: 'A$ - Australia Dollars', value: 'AUD' },
  { label: 'C$ - Canada Dollars', value: 'CAD' },
  { label: 'NZ$ - New Zealand Dollars', value: 'NZD' },
  { label: 'R - South African RAND', value: 'ZAR' },
  { label: 'CI$ - Cayman Islands', value: 'KYD' },
  { label: '฿ - Thai Baht', value: 'TB' }
];

export const SUPPORTED_TIMEZONES = [
  { label: 'GMT', value: 'GMT' },
  { label: 'Central Time Zone', value: 'Central Time Zone' },
  { label: 'Eastern Time Zone', value: 'Eastern Time Zone' },
  { label: 'Pacific Time Zone', value: 'Pacific Time Zone' },
  { label: 'Newfoundland Time Zone', value: 'Newfoundland Time Zone' },
  { label: 'Atlantic Time Zone', value: 'Atlantic Time Zone' },
  { label: 'Mountain Time', value: 'Mountain Time' },
  { label: 'Hawaiian Time', value: 'Hawaiian Time' },
  { label: 'Alaskan Time', value: 'Alaskan Time' },
  { label: 'New Zealand Standard Time Zone', value: 'New Zealand Standard Time Zone' },
  { label: 'Australian Eastern Standard Time', value: 'Australian Eastern Standard Time' },
  { label: 'Australian Central Standard Time', value: 'Australian Central Standard Time' },
  { label: 'Eastern European Time Zone', value: 'Eastern European Time Zone' },
  { label: 'Western European Time Zone', value: 'Western European Time Zone' }
];

export const SUPPORTED_LANGUAGES = [
  { label: 'English', value: 'English' },
  { label: 'Spanish', value: 'Spanish' }
];

export const SUPPORTED_DOMAINS = {
  main: [{ label: 'searchallproperties.xyz', value: 'searchallproperties.xyz' }],
  listings: [{ label: 'thebestlisting.site', value: 'thebestlisting.site' }],
  reviews: [{ label: 'bestclientreviews.site', value: 'bestclientreviews.site' }],
  bio: [{ label: 'seebio.site', value: 'seebio.site' }],
  headers: [{ label: 'searchallproperties.xyz', value: 'searchallproperties.xyz' }],
  freeHomeValue: [{ label: 'freehomevalues.site', value: 'freehomevalues.site' }]
};

export const HOME_ROUTE_NAME = 'GetStartedView';

export const SEARCH_ALL_PROPERTIES_DNS = 'dns.searchallproperties.xyz';

export const URL_PREFIX = 'https://'; // this will be used to have a unified way to build the urls ex. domains and lead links
export const URL_REGEX = /((http|https):\/\/[^\s?]+)/g; // ex https://www.example.com/post?query=1

export const DRAGGABLE_OPTIONS = {
  animation: 250,
  group: 'people',
  disabled: false,
  ghostClass: 'draggable-ghost'
};

// These script tools are used to make sure the values & labels are consistent across the app in all script tools
export const SCRIPT_TOOLS_OPTIONS = {
  listings: {
    label: 'Listings',
    value: 'listings'
  },
  landingPages: {
    label: 'Landing Pages',
    value: 'landing_pages'
  },
  listingPresentation: {
    label: 'Listing Presentation',
    value: 'listing_presentation'
  },
  clientReviewWebsite: {
    label: 'Client Review Website',
    value: 'review'
  }
};
export const SCRIPT_TYPES_OPTIONS = {
  shortFormVideo: {
    label: 'Short Form Video',
    value: 'short_form_video'
  },
  circleProspecting: {
    label: 'Circle Prospecting',
    value: 'circle_prospecting'
  },
  databaseCall: {
    label: 'Database Call',
    value: 'database_call'
  },
  databaseColdCalling: {
    label: 'Database/Cold Calling',
    value: 'database_cold_calling'
  },
  farmCircleProspecting: {
    label: 'Farm/Circle Prospecting',
    value: 'farm_circle_prospecting'
  }
};

// country options
export const SUPPORTED_COUNTRIES = [
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Cape Verde', value: 'Cape Verde' },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Chile', value: 'Chile' },
  { label: 'China', value: 'China' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Congo (Brazzaville)', value: 'Congo (Brazzaville)' },
  { label: 'Congo (Kinshasa)', value: 'Congo (Kinshasa)' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'East Timor', value: 'East Timor' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Eswatini', value: 'Eswatini' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'India', value: 'India' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Ivory Coast', value: 'Ivory Coast' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Kosovo', value: 'Kosovo' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Micronesia', value: 'Micronesia' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Myanmar (Burma)', value: 'Myanmar (Burma)' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'North Korea', value: 'North Korea' },
  { label: 'North Macedonia', value: 'North Macedonia' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'South Korea', value: 'South Korea' },
  { label: 'South Sudan', value: 'South Sudan' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Syria', value: 'Syria' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'United States', value: 'United States' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Vatican City', value: 'Vatican City' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' }
];
