import './assets/css/main.css';

import App from './App.vue';
import { createApp } from 'vue';
import { setupNaive } from '@/plugins/naive';
import { setupI18n } from '@/i18/index.js';
import setupPiniaStore from '@/stores/pinia';
import { setupRouter } from '@/router';
import { useNewProfileStore } from '@/stores/profile/index.js';
import { useAuthStore } from './stores/auth';

// Function to validate user
async function handleValidateUser() {
  const profileStore = useNewProfileStore();
  const authStore = useAuthStore();

  // only if we have a token we can validate the user
  if (!authStore.getToken) {
    return;
  }

  const loader = document.createElement('div');
  loader.className = 'full-loader';
  loader.innerHTML = `
    <div class="full-loader-spinner"></div>
    <div>Loading...</div>
  `;
  document.body.appendChild(loader);

  const { error } = await profileStore.useGetProfileGeneralData({ loadingKey: 'validateUser' });

  document.body.removeChild(loader);

  if (error) {
    // since we are validating the user all errors should be 401
    authStore.useLogout();
  }
}

// Main initialization function
async function initApp() {
  const app = createApp(App);

  // Setup app features
  setupNaive(app);
  setupPiniaStore(app);
  setupI18n(app);

  try {
    // Validate user
    await handleValidateUser();
  } catch (err) {
    console.error('Error loading the app:', err);
  } finally {
    // Setup router
    setupRouter(app);

    // Create a meta tag for Naive UI styles
    // https://www.naiveui.com/en-US/os-theme/docs/style-conflict#About-Tailwind's-Preflight-Style-Override
    const meta = document.createElement('meta');
    meta.name = 'naive-ui-style';
    document.head.appendChild(meta);

    // Mount the app to the HTML element with the id "app"
    app.mount('#app');
  }
}

// Call the main initialization function
initApp();
