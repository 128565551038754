import { createI18n } from 'vue-i18n';
import numberFormats from './rules/numbers.js';
import datetimeFormats from './rules/datetime.js';
import es from './locales/es/index.js';
import en from './locales/en/index.js';

// tutorial https://lokalise.com/blog/vue-i18n/
export const i18n = createI18n({
  locale: localStorage.getItem('user-locale') || import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false, // you must set `false`, to use Composition API,
  globalInjection: true,
  numberFormats,
  datetimeFormats,
  messages: {
    ...es,
    ...en
  }
});

export function setupI18n(app) {
  app.use(i18n);
}
