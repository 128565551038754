import common from '@/i18/locales/en/common.json';
import home from '@/i18/locales/en/home.json';
import login from '@/i18/locales/en/login.json';
import signup from '@/i18/locales/en/signup.json';
import about from '@/i18/locales/en/about.json';
import navbar from '@/i18/locales/en/navbar.json';
import profile from '@/i18/locales/en/profile.json';

export default {
  en: {
    common: common,
    navbar: navbar,
    home: home,
    login: login,
    signup: signup,
    about: about,
    profile: profile
  }
};
