<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :width="props.size" :height="props.size">
    <g fill="none" :stroke="props.color" stroke-linecap="round" stroke-linejoin="round" :stroke-width="props.strokeWidth">
      <path d="M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <path d="M7 15v-4a2 2 0 0 1 4 0v4m-4-2h4m6-4v6h-1.5a1.5 1.5 0 1 1 1.5-1.5" />
    </g>
  </svg>
</template>

<script setup>
const props = defineProps({
  size: {
    type: Number,
    default: 16
  },
  color: {
    type: String,
    default: 'currentColor'
  },
  strokeWidth: {
    type: String,
    default: '1.5'
  }
});
</script>
