<template>
  <n-config-provider :theme="theme" :theme-overrides="themeOverrides">
    <n-dialog-provider>
      <n-notification-provider>
        <!-- see https://www.naiveui.com/en-US/os-theme/components/message > MessageProvider Props -->
        <n-message-provider :duration="5000" :max="5" :keepAliveOnHover="true">
          <!-- <n-theme-editor> -->
          <slot name="default"></slot>
          <!-- </n-theme-editor> -->
        </n-message-provider>
      </n-notification-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>

<script setup>
import { useAppConfigStore } from '@/stores/app-config';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

import { NThemeEditor } from 'naive-ui';

const appConfigStore = useAppConfigStore();
const { getTheme } = storeToRefs(appConfigStore);

const theme = computed(() => {
  return getTheme.value;
});

const themeOverrides = computed(() => {
  if (!theme.value) {
    return {
      common: {
        primaryColor: '#14AF42FF',
        primaryColorHover: '#119237FF',
        primaryColorPressed: '#0C762BFF'
      },
      Button: {
        borderRadiusMedium: '4px',
        borderRadiusLarge: '4px',
        borderRadiusSmall: '4px',
        heightLarge: '42px',
        heightMedium: '36px',
        heightSmall: '30px',
        heightTiny: '24px',
        paddingTiny: '0 10px',
        paddingSmall: '0 14px',
        paddingLarge: '0 20px',
        paddingMedium: '0 16px',
        heightTiny: '26px',
        heightSmall: '32px',
        heightMedium: '36px'
      },
      Layout: {
        color: '#FCFCFCFF'
      },
      Card: {
        borderRadius: '6px'
      }
    };
  } else {
    return {
      common: {
        primaryColor: '#4caf50',
        primaryColorHover: '#119237FF',
        primaryColorPressed: '#2e7d32'
      },
      Button: {
        borderRadiusMedium: '6px',
        borderRadiusLarge: '6px',
        borderRadiusSmall: '6px',
        heightLarge: '42px',
        heightMedium: '36px',
        heightSmall: '30px',
        heightTiny: '24px',
        paddingTiny: '0 10px',
        paddingSmall: '0 14px',
        paddingLarge: '0 20px',
        paddingMedium: '0 16px',
        heightTiny: '26px',
        heightSmall: '32px',
        heightMedium: '36px'
      },
      Layout: {
        color: '#38383DFF'
      },
      Card: {
        borderRadius: '6px'
      }
    };
  }
});

onMounted(() => {
  const theme = localStorage.getItem('app-config') ? JSON.parse(localStorage.getItem('app-config')).theme : null;

  appConfigStore.setTheme({ theme: theme });
});
</script>
