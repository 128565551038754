import { useAuthStore } from '@/stores/auth';
import router from '..';
import { AUTH_ROUTES, PRIVATE_ROUTES } from '../routes';
import { HOME_ROUTE_NAME } from '@/global/globalVariables';
import { useNewProfileStore } from '@/stores/profile';

const usePermissionGuard = () => {
  const userStore = useAuthStore();
  const profileStore = useNewProfileStore();

  router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    if (to.meta.isAuth) {
      if (!userStore.getToken) {
        next({ name: 'LoginView' });
        return;
      }
    }

    // prevent user from accessing permission restricted pages (e.g. broker specific pages)
    if (to.meta.permission) {
      if (profileStore.getUserType !== to.meta.permission.toLowerCase()) {
        next({ name: HOME_ROUTE_NAME });
        return;
      }
    }

    // redirect to dashboard if logged in and trying to access AUTH_ROUTES
    AUTH_ROUTES.map(route => {
      if (route.name === to.name) {
        if (userStore.getToken) {
          next({ name: HOME_ROUTE_NAME });
          return;
        }
      }
    });

    // redirect to login if logged in and trying to access PRIVATE_ROUTES
    PRIVATE_ROUTES.map(route => {
      if (route.name === to.name) {
        if (!userStore.getToken) {
          next({ name: 'LoginView' });
          return;
        }
      }
    });

    next();
  });
};

export default usePermissionGuard;
