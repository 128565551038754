import { useAuthStore } from '@/stores/auth';
import axios from 'axios';
import { CONSTANTS_URL } from '@/global/globalVariables';
import { useAppConfigStore } from '@/stores/app-config';

const AXIOS_INSTANCE = axios.create({
  baseURL: CONSTANTS_URL.baseApiUrl,
  timeout: 60000, // 1 minute
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: true
});

// handling global response status
AXIOS_INSTANCE.interceptors.response.use(
  response => {
    let res = {
      data: response?.data,
      status: response?.status,
      headers: response?.headers
    };

    console.log('response from server', res);

    return res;
  },
  error => {
    let err = {
      data: error.response?.data,
      source: error.response?.config.url, // url that cause error
      status: error.response?.status
    };

    console.log('response from server', error);

    const appConfigStore = useAppConfigStore();
    appConfigStore.setApiState({ status: err.status, source: err.source });

    return Promise.reject(err);
  }
);

// attaching token to every request
AXIOS_INSTANCE.interceptors.request.use(
  config => {
    const userStore = useAuthStore();
    if (userStore.getToken) {
      config.headers.Authorization = `Bearer ${userStore.getToken}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default AXIOS_INSTANCE;
