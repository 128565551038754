import { defineStore } from 'pinia';
import AXIOS_INSTANCE from '@/api/axios.config.js';
import router from '@/router';
import { resetAllStores } from './pinia/resetAllStores';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    token: localStorage.getItem('token') || null,
    loadingKeys: []
  }),

  getters: {
    getToken() {
      return this.token;
    },
    getLoadingKeys() {
      return this.loadingKeys;
    }
  },

  actions: {
    useSetUser({ user }) {
      if (!user) {
        return console.error(`You passed an empty user object, source: useSetUser`);
      }

      this.user = { ...user };
      // setting token
      this.useSetToken({ token: user.token });
    },

    useSetToken({ token, saveToStorage = true }) {
      if (!token) {
        return console.error(`You passed an empty token, source: useSetToken()`);
      }

      this.token = token;
      if (saveToStorage) {
        localStorage.setItem('token', token);
      }
    },

    async useAuth({ payload, loadingKey, actionType }) {
      const supportedType = ['login', 'signup'];
      let reqUrl = '';

      if (!supportedType.includes(actionType)) {
        throw new Error(`useAuth actionType must be one of ${supportedType.join(', ')}`);
      }

      if (actionType === 'login') {
        reqUrl = '/auth/login';
      } else if (actionType === 'signup') {
        reqUrl = '/auth/register';
      }

      try {
        this.loadingKeys.push(loadingKey);
        const response = await AXIOS_INSTANCE.post(reqUrl, payload);

        const user = response.data?.data || response.data;

        try {
          this.useSetUser({ user });
        } catch (err) {
          console.error('errooooor', err);
        }

        return {
          success: response.data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: error?.data
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    async useLogout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem('token');
      // removing redirectUrl from localStorage
      localStorage.removeItem('redirectUrl');
      AXIOS_INSTANCE.defaults.headers.common['Authorization'] = null;
      resetAllStores();

      router.push({ name: 'LoginView' });
    },

    // validating the token sent from admin to impersonate a user
    async useExchangeToken({ token }) {
      try {
        const response = await AXIOS_INSTANCE.post('/auth/exchange', { token });

        const data = response.data?.data || response.data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: error?.data
        };
      }
    }
  }
});
