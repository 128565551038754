import {
  create,
  NAlert,
  NAvatar,
  NBackTop,
  NBadge,
  NBreadcrumb,
  NBreadcrumbItem,
  NButton,
  NButtonGroup,
  NCard,
  NCheckbox,
  NCheckboxGroup,
  NCol,
  NCollapse,
  NCollapseItem,
  NCollapseTransition,
  NColorPicker,
  NConfigProvider,
  NDataTable,
  NDatePicker,
  NDescriptions,
  NDescriptionsItem,
  NDialogProvider,
  NDivider,
  NDrawer,
  NDrawerContent,
  NDropdown,
  NDynamicTags,
  NElement,
  NEllipsis,
  NEmpty,
  NForm,
  NFormItem,
  NFormItemGi,
  NGrid,
  NGridItem,
  NH1,
  NH2,
  NH3,
  NH4,
  NH5,
  NH6,
  NIcon,
  NImage,
  NInput,
  NInputGroup,
  NInputGroupLabel,
  NInputNumber,
  NLayout,
  NLayoutContent,
  NLayoutFooter,
  NLayoutHeader,
  NLayoutSider,
  NList,
  NListItem,
  NLoadingBarProvider,
  NMenu,
  NMessageProvider,
  NModal,
  NNotificationProvider,
  NP,
  NPageHeader,
  NPagination,
  NPopover,
  NPopconfirm,
  NProgress,
  NRadio,
  NRadioButton,
  NRadioGroup,
  NResult,
  NRow,
  NScrollbar,
  NSelect,
  NSkeleton,
  NSlider,
  NSpace,
  NSpin,
  NStatistic,
  NStep,
  NSteps,
  NSwitch,
  NTab,
  NTable,
  NTabPane,
  NTabs,
  NTag,
  NText,
  NThing,
  NTimePicker,
  NTooltip,
  NTree,
  NUpload,
  NUploadDragger
} from 'naive-ui';

// https://www.naiveui.com/en-US/os-theme/docs/import-on-demand
const naive = create({
  components: [
    NMessageProvider,
    NDialogProvider,
    NConfigProvider,
    NInput,
    NButton,
    NForm,
    NFormItem,
    NCheckboxGroup,
    NCheckbox,
    NIcon,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
    NLayoutFooter,
    NLayoutSider,
    NMenu,
    NBreadcrumb,
    NBreadcrumbItem,
    NDropdown,
    NSpace,
    NTooltip,
    NAvatar,
    NTabs,
    NTab,
    NTabPane,
    NCard,
    NRow,
    NCol,
    NDrawer,
    NDrawerContent,
    NDivider,
    NSwitch,
    NBadge,
    NAlert,
    NElement,
    NEllipsis,
    NTag,
    NNotificationProvider,
    NProgress,
    NDatePicker,
    NGrid,
    NGridItem,
    NList,
    NListItem,
    NThing,
    NDataTable,
    NPopover,
    NPopconfirm,
    NPagination,
    NSelect,
    NRadioGroup,
    NRadio,
    NRadioButton,
    NSteps,
    NStep,
    NInputGroup,
    NInputGroupLabel,
    NResult,
    NDescriptions,
    NDescriptionsItem,
    NTable,
    NInputNumber,
    NLoadingBarProvider,
    NModal,
    NUpload,
    NTree,
    NSpin,
    NTimePicker,
    NColorPicker,
    NBackTop,
    NSkeleton,
    NPageHeader,
    NText,
    NP,
    NH1,
    NH2,
    NH3,
    NH4,
    NH5,
    NH6,
    NScrollbar,
    NDynamicTags,
    NUploadDragger,
    NImage,
    NButtonGroup,
    NCollapse,
    NCollapseItem,
    NStatistic,
    NEmpty,
    NSlider,
    NCollapseTransition,
    NFormItemGi
  ]
});

export function setupNaive(app) {
  app.use(naive);
}
