import { createDiscreteApi } from 'naive-ui';
import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import { PRIVATE_ROUTES } from '@/router/routes';

const { message } = createDiscreteApi(['message'], {
  messageProviderProps: {
    duration: 5000,
    placement: 'top-right'
  }
});

export const checkApiStatus = ({ status }) => {
  switch (status) {
    case 400:
      // message.error('Bad Request. Please check your input and try again.');
      break;
    case 401:
      handle401();
      break;
    case 403:
      // message.error('Forbidden. You do not have permission to access this resource.');
      break;
    case 404:
      // message.error('Not Found. The resource you are looking for does not exist.');
      break;
    case 405:
      // message.error('Method Not Allowed. Please check the request method and try again.');
      break;
    case 500:
      // message.error('Internal Server Error. Please try again later.');
      break;
    case 502:
      // message.error('Bad Gateway. Please try again later.');
      break;
    case 503:
      // message.error('Service Unavailable. Please try again later.');
      break;
    case 504:
      // message.error('Gateway Timeout. Please try again later.');
      break;
    default:
      break;
  }
};

const handle401 = () => {
  const userStore = useAuthStore();
  let isPrivateRoute = false;

  userStore.loadingKeys.push('removeUser');

  // check if the current route is a private route
  PRIVATE_ROUTES.forEach(route => {
    const { isCurrentRouteNamePrivate } = checkIfPrivateRoute(route);

    if (isCurrentRouteNamePrivate) {
      isPrivateRoute = true;
    }
  });

  // if the current route is a private route, remove the user and redirect to login
  if (isPrivateRoute) {
    userStore.useLogout();
  } else {
    // if the current route is not a private route, just remove the user
    userStore.useLogout();
  }
};

const checkIfPrivateRoute = route => {
  let isCurrentRouteNamePrivate = false;

  if (route.name === router.currentRoute.value.name) {
    isCurrentRouteNamePrivate = true;
  }

  if (route.children) {
    route.children.forEach(child => {
      checkIfPrivateRoute(child);
    });
  }

  return {
    isCurrentRouteNamePrivate
  };
};
