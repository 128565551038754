import router from '..';
import { CONSTANTS_COMPANY } from '@/global/globalVariables';

const usePageTitleGuard = () => {
  const { name: appTitle } = CONSTANTS_COMPANY;

  router.afterEach(to => {
    if (to.meta && to.meta.title) {
      const title = to.meta.title;
      document.title = `${title} | ${appTitle}`;
    } else {
      document.title = appTitle;
    }
  });
};

export default usePageTitleGuard;
