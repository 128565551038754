import common from '@/i18/locales/es/common.json';
import home from '@/i18/locales/es/home.json';
import login from '@/i18/locales/es/login.json';
import signup from '@/i18/locales/es/signup.json';
import about from '@/i18/locales/es/about.json';
import navbar from '@/i18/locales/es/navbar.json';
import profile from '@/i18/locales/es/profile.json';

export default {
  es: {
    common: common,
    navbar: navbar,
    home: home,
    login: login,
    signup: signup,
    about: about,
    profile: profile
  }
};
