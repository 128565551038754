export default {
  en: {
    currencyFormat: {
      style: 'currency',
      currency: 'USD'
    }
  },
  // mexican peso
  es: {
    currencyFormat: {
      style: 'currency',
      currency: 'MXN'
    }
  }
};
